<template>
  <div
    id="preview"
    class="ml-auto mr-auto"
  >
    <div class="image">
      <img src="/media/hr/baby.jpg">
    </div>
    <div class="hr-title">
      <h3>{{ getFormData.text1 }}</h3>
    </div>
    <div class="hr-content">
      <h5>{{ getFormData.text2 }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Baby',
  computed: {
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;400;600&display=swap');

#preview{
  width: 800px;
  height: 450px;
  position: relative;
  overflow: hidden;
}
.hr-title {
  position: absolute;
  bottom: 110px;
  left: 50%;
  text-align: center;
  width: 350px;
  transform: translateX(-50%);
}
.hr-title h3 {
  color: #000;
  font-family: 'Exo', sans-serif;
  font-weight: 600;
  margin: 0;
}
.hr-content {
  position: absolute;
  bottom: 30px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
}
.hr-content h5 {
  color: #000;
  font-family: 'Exo', sans-serif;
  font-weight: 600;
  margin: 0;
  font-size: 16px;
}
</style>
