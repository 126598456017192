<template>
  <div>
    <div
      id="preview"
      class="ml-auto mr-auto"
    >
      <div class="image">
        <img src="/media/hr/leaving.jpg">
      </div>
      <div class="hr-title">
        <h3>{{ getFormData.text1 }}</h3>
      </div>
    </div>
  </div></template>

<script>

export default {
  name: 'Leaving',
  computed: {
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;400;600&display=swap');

#preview{
  width: 800px;
  height: 800px;
  position: relative;
  overflow: hidden;
}
.hr-title {
  position: absolute;
  top: 330px;
  left: 50%;
  text-align: center;
  width: 380px;
  transform: translateX(-50%);
}
.hr-title h3 {
  color: #000;
  font-family: 'Exo', sans-serif;
  margin: 0;
  font-size: 20px;
  font-weight: 300;
}
</style>
