<template>
  <div>
    <div
      id="preview"
      class="ml-auto mr-auto"
    >
      <div class="image">
        <img
          :src="'/media/hr/seniority/seniority-' + getFormData.text2 + '.jpg'"
        >
      </div>
      <div class="hr-title">
        <h3>{{ getFormData.text1 }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Seniority',
  computed: {
    getFormData() {
      return this.$store.getters['hrNotifications/getHr_notification']
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@300;400;600&display=swap');

#preview{
  width: 800px;
  height: 800px;
  position: relative;
  overflow: hidden;
}
.hr-title {
  position: absolute;
  bottom: 210px;
  left: 50%;
  text-align: center;
  width: 600px;
  transform: translateX(-50%);
}
.hr-title h3 {
  color: #000;
  font-family: 'Exo', sans-serif;
  margin: 0;
  font-weight: 600;
  font-size: 48px;
}
</style>
